import classNames from 'classnames';
import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import {
  isLandscape,
  setBodyHeightAuto,
  setBodyHeightOneHundred,
  setBodyOverflowAuto,
  setBodyOverflowHidden,
} from '../../../utils';
import { GameLoader } from '../../atoms/GameLoader/Loader';
import { ArenaConfig } from '../../models/ArenaConfig';
import { GameState, GameUnitState } from '../../models/Enums';
import { Game } from '../../models/Game';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import { DeviceDetector } from '../../services/DeviceDetector';
import { setGameUnitState } from '../../store/ducks/gameState';
import styles from './Game.css';
import { GameCanvas } from './GameCanvas';
import { GameObservable, PurchaseRequestEvent } from '../../models/GameObservable';

export type TProps = {
  game: Game;
  config: ArenaConfig;
  gameState: GameState;
  isDisplayAdsEnabled: boolean;
  dispatch?: any;
  isForMobileCrawling: boolean;
  canvasBoxRef: RefObject<HTMLIFrameElement>;
  onIframeLoad: () => Promise<void>;
  iframeSourceCode: string;
  isIframeGame: boolean;
  isGameLoading: boolean;
  currentLang: string;
  onReward: (e: Event) => void;
  onInterstitial: (e: Event) => void;
  onRewardedAdRequested: (e: Event) => void;
  onRewardedAdShown: (e: Event) => void;
  onInterstitialAdRequested: (e: Event) => void;
  onInterstitialAdShown: (e: Event) => void;
  aspectRatio: any;
  gameSubject: GameObservable;
  stopMobileCrawlingRender: () => void;
  gameStartTime: Date;
  savePurchaseRequest: (event: PurchaseRequestEvent) => void;
};

type TState = {
  isLandscape: boolean;
  fullScreenMob: boolean;
  fullScreenMobTheySell: boolean;
  fullScreenTab: boolean;
};

class GameUnitBase extends React.PureComponent<TProps, TState> {
  containerRef = React.createRef<HTMLDivElement>();
  canvasContainerRef = React.createRef<HTMLDivElement>();
  calculateFullScreenProps = () => {
    return {
      fullScreenMob:
        DeviceDetector.isMobile() &&
        !this.props.isForMobileCrawling &&
        !this.props.config.ad?.theySell?.display &&
        this.props.isDisplayAdsEnabled,
      fullScreenMobTheySell:
        DeviceDetector.isMobile() &&
        !this.props.isForMobileCrawling &&
        (Boolean(this.props.config.ad?.theySell?.display) || !this.props.isDisplayAdsEnabled),
      fullScreenTab: DeviceDetector.isTablet() && !this.props.isForMobileCrawling,
    };
  }
  state = {
    isLandscape: true,
    ...this.calculateFullScreenProps(),
  };

  onResize = () => {
    const { isForMobileCrawling } = this.props;
    const container = this.containerRef.current as any;
    const canvas = this.canvasContainerRef.current as any;

    if (!container || !canvas) {
      return;
    }

    const notPc = DeviceDetector.isNotPc();

    if (notPc && !isForMobileCrawling) {
      window.scrollTo(0, 0);
      setBodyOverflowHidden();
      setBodyHeightOneHundred();
      this.setState({ isLandscape: isLandscape() });
    } else {
      setBodyOverflowAuto();
      setBodyHeightAuto();
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>) {
    this.onResize();
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 0);

    if (prevProps.isForMobileCrawling !== this.props.isForMobileCrawling) {
      const {
        fullScreenMob,
        fullScreenMobTheySell,
        fullScreenTab,
      } = this.calculateFullScreenProps();

      this.setState({
        fullScreenMob,
        fullScreenMobTheySell,
        fullScreenTab,
      });

      if (fullScreenMob) {
        this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB));
      }

      if (fullScreenMobTheySell) {
        this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB_THEY_SELL));
      }

      if (fullScreenTab) {
        this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_TAB));
      }
    }
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);

    if (DeviceDetector.isNotPc()) {
      setBodyOverflowAuto();
      setBodyHeightOneHundred();
    }
  }

  render() {
    const {
      isDisplayAdsEnabled,
      gameState,
      config,
    } = this.props;
    const isGameState = gameState === GameState.GAME;
    const isDisplayAdsVisible = isDisplayAdsEnabled && DeviceDetector.isNotPc() && isGameState;
    const isTheySell = config.ad?.theySell?.display;
    const mobTopAdSizes =
      this.state.isLandscape || isTheySell
        ? [[320, 50]]
        : [[320, 50], [320, 100]];

    // focusing on the game after video ad is finished
    // this is fix for IOS old versions, some games lost focus and paused
    if (isGameState) {
      const iframe = document.querySelector('#game-canvas iframe');
      const canvas = document.querySelector('#game-canvas canvas');
      const gameBlock: any = iframe || canvas;

      if (gameBlock) {
        gameBlock.focus();
      }

      window.dispatchEvent(new Event('game:start')); // do not remove this because external scripts use the event
    }

    return (
      <UnitContainer
        ref={this.containerRef}
        hidden={
          this.props.gameState === GameState.PREROLL ||
          this.props.gameState === GameState.REWARD ||
          this.props.gameState === GameState.INTERSTITIAL
        }
      >
        {isDisplayAdsVisible && (
          <GameAd data-element-description="game top ad">
            {DeviceDetector.isTablet() && (
              <DisplayAd
                componentId="ark_display_t1"
                dataElementDescription="ark-display-m1"
                dimensions={[[728, 90]]}
              />
            )}
            {DeviceDetector.isMobile() && !this.state.isLandscape && (
              <DisplayAd
                componentId="ark_display_m1"
                dataElementDescription="ark-display-m1"
                dimensions={mobTopAdSizes}
              />
            )}
            {DeviceDetector.isMobile() && this.state.isLandscape && (
              <DisplayAd
                componentId="ark_display_m1"
                dataElementDescription="ark-display-m1"
                dimensions={mobTopAdSizes}
              />
            )}
          </GameAd>
        )}

        <CanvasContainer
          ref={this.canvasContainerRef}
          isIframeGame={this.props.isIframeGame}
          fullScreenMob={this.state.fullScreenMob}
          fullScreenMobTheySell={this.state.fullScreenMobTheySell}
          fullScreenTab={this.state.fullScreenTab}
        >
          <GameCanvas
            id="game-canvas"
            canvasBoxRef={this.props.canvasBoxRef}
            onIframeLoad={this.props.onIframeLoad}
            iframeSourceCode={this.props.iframeSourceCode}
            isIframeGame={this.props.isIframeGame}
          />
        </CanvasContainer>

        {this.props.isGameLoading && this.props.gameState === GameState.GAME && (
          <GameLoader isForMobileCrawling={this.props.isForMobileCrawling} />
        )}
      </UnitContainer>
    );
  }
}

const UnitContainer = React.forwardRef<any, any>((
  {
    hidden,
    isForMobileCrawling,
    ...props
  }: any,
  ref,
) => (
  <div
    className={classNames(styles.unitContainer, {
      [styles.hidden]: hidden,
      [styles.fullScreen]: DeviceDetector.isNotPc() && isForMobileCrawling,
    })}
    {...props}
    ref={ref}
  />
));
const GameAd = (props: any) => <div className={styles.gameAd} {...props} />;
const CanvasContainer = React.forwardRef<any, any>(
  (
    {
      fullScreenMob,
      fullScreenMobTheySell,
      fullScreenTab,
      isIframeGame,
      ...restProps
    }: any,
    ref,
  ) => {
    return (
      <div
        className={classNames(styles.canvasContainer, {
          [styles.isIframeGame]: isIframeGame,
          [styles.fullScreenMobTheySell]: fullScreenMobTheySell,
          [styles.fullScreenMob]: fullScreenMob,
          [styles.fullScreenTab]: fullScreenTab,
        })}
        {...restProps}
        ref={ref}
      />
    );
  },
);

export const GameUnit = connect()(GameUnitBase);
