export enum KeysEnum {
  wapoUserId = 'wapo_login_id',
  wapoUserName = 'wapo_display',
  arkStateReturnUrl = 'ark_state_return_url',
  arkStatePlayedGame = 'ark_state_played_game',
  arkToken = 'ark_arenax_token',
  arkTokenExpiresIn = 'ark_arenax_token_expires_in',
  arena4FirstVisitAt = 'arkUserFirstVisit',
  arena4UserType = 'Arena_usertype',
  arkVideoAdCustomEnv = 'ark_video_ad_custom_env', // ls
  arkDisplayAdCustomEnv = 'ark_display_ad_custom_env', // ls
  arkFirstVisitAt = 'ark_arenax_first_visit_at', // cookie
  arkUserType = 'ark_arenax_user_type', // cookie
  arkFtuxShown = 'ark_arenax_ftux-shown', // ls
  wasAdBlocker = 'ark_ad_blocker', // ls
  gamePlayCount = 'ark_game_play_count', // ls
  lastGamePlay = 'ark_last_gameplay', // ls
  arkApiToken = 'arenax_api_token',
  arkabtests = 'arkabtests',
}

export enum AnnounceMessageKeys {
  migratedArena4Shown = 'ark_arenax_msg_migrated_4-shown',
  dailySudokuShown = 'ark_arenax_msg_daily_sudoku-shown',
  removeEuronewsArenaShown = 'ark_arenax_msg_remove_euronews_arena-showm',
  easyCrosswordShown = 'ark_arenax_msg_easy_crossword-announcement-shown',
  internetExplorerShown = 'ark_arenax_msg_internet_explorer-shown',
  metvAtariGamesShown = 'ark_arenax_msg_metv_atari_games-shown',
  greatergoodPromoMessage = 'ark_arenax_msg_greatergood_promo_message-shown',
  wapoSurveyShown = 'ark_arenax_msg_wapo_survey-shown',
  wapoMessage = 'ark_arenax_msg_wapo',
  eagleMigrationShown = 'ark_arenax_msg_eagle_migration-shown',
  TEST_MESSAGE = 'ark_arenax_test_message-shown',
  metvPlayingOurGames = 'ark_arenax_msg_metv_playing_our_games',
  iheartdogsDonatedShelter = 'ark_arenax_msg_iheartdogs_donated_shelter',
  itvMessage = 'ark_arenax_msg_itv',
  abqJournalTransitionMessage = 'ark_arenax_msg_abqjournal_transition-shown',
  gamelabJourneyMatchMessage = 'ark_arenax_msg_gamelab_journey-shown',
  wapoLBIssueMessage = 'ark_arenax_msg_wapo_lb-shown',
  dictionaryWelcMessage = 'dictionary_welc-shown',
  usatCrosswordPromoMessage = 'ark_arenax_msg_usat_crosswords_promo-shown',
  leMondeShutDownMessage = 'ark_arenax_le_monde_shut_down_message-shown',
  gamesArkWelcomeMessage = 'ark_arenax_games_ark_welcome_message-shown',
  starTribuneSubscriptionMessage = 'ark_arenax_star_tribune_subscription-shown',
  bostonGlobeMessage = 'ark_bostonglobe-shown',
  bostonComWelcomeMessage = 'ark_boston-welcome-shown',
}

export const AnnounceMessageTransKeys = {
  [AnnounceMessageKeys.migratedArena4Shown]: 'MIGRATED_4_ARENA_MESSAGE',
  [AnnounceMessageKeys.dailySudokuShown]: 'DAILY_SUDOKU_MESSAGE',
  [AnnounceMessageKeys.removeEuronewsArenaShown]: 'REMOVE_EURONEWS_ARENA_MESSAGE',
  [AnnounceMessageKeys.internetExplorerShown]: 'INTERNET_EXPLORER_MESSAGE',
  [AnnounceMessageKeys.metvAtariGamesShown]: 'METV_ATARI_GAMES_MESSAGE',
  [AnnounceMessageKeys.greatergoodPromoMessage]: 'GREATERGOOD_PROMO_MESSAGE',
  [AnnounceMessageKeys.wapoSurveyShown]: 'WAPO_SURVEY_MESSAGE',
  [AnnounceMessageKeys.eagleMigrationShown]: 'EAGLE_MIGRATION_MESSAGE',
  [AnnounceMessageKeys.TEST_MESSAGE]: 'TEST_ANNOUNCE_MESSAGE',
  [AnnounceMessageKeys.metvPlayingOurGames]: 'METV_PLAYING_OUR_GAMES',
  [AnnounceMessageKeys.iheartdogsDonatedShelter]: 'IHEARTDOGS_DONATED_SHELTER',
  [AnnounceMessageKeys.abqJournalTransitionMessage]: 'ABQJOURNAL_TRANSITION_MESSAGE',
  [AnnounceMessageKeys.gamelabJourneyMatchMessage]: 'GAMELAB_JOURNEY_MESSAGE',
  [AnnounceMessageKeys.wapoLBIssueMessage]: 'WAPO_LBISSUE_MESSAGE',
  [AnnounceMessageKeys.wapoMessage]: 'WAPO_MESSAGE',
  [AnnounceMessageKeys.dictionaryWelcMessage]: 'DICTIONARY_WELC_MESSAGE',
  [AnnounceMessageKeys.usatCrosswordPromoMessage]: 'USAT_CROSSWORD_PROMO_MESSAGE',
  [AnnounceMessageKeys.leMondeShutDownMessage]: 'LE_MONDE_SHUT_DOWN_MESSAGE',
  [AnnounceMessageKeys.gamesArkWelcomeMessage]: 'GAMES_ARK_WELCOME_MESSAGE',
  [AnnounceMessageKeys.starTribuneSubscriptionMessage]: 'STAR_TRIBUNE_SUBSCRIPTION_MESSAGE',
  [AnnounceMessageKeys.bostonGlobeMessage]: 'BOSTONGLOB_MESSAGE',
  [AnnounceMessageKeys.bostonComWelcomeMessage]: 'BOSTON_WELCOME_MESSAGE',
  [AnnounceMessageKeys.itvMessage]: 'ITV_MESSAGE',
};

export const AnnounceWaPoQueryParams = {
  'Arena Homepage': 'ah',
  'Outspell': 'os',
  'Mahjongg Solitaire': 'ms',
  'Sudoku': 'sd',
  'Klondike Solitaire': 'ks',
  'Block Champ': 'bc',
  'Mahjongg Dimensions': 'md',
  'Word Wipe': 'ww',
  'Classic Solitaire': 'cs',
  'Mahjongg Dark Dimensions': 'mdd',
  'BlackJack': 'bj',
  'FreeCell Solitaire': 'fcs',
  'Spider Solitaire': 'ss',
  'Mahjongg Candy': 'mc',
  'Arkadium\'s Bubble Shooter': 'bs',
  '10x10': 'tn',
};

export enum GameState {
  GAME = 'GAME',
  GAME_END = 'GAME_END',
  GAME_SCORE_SAVED = 'GAME_SCORE_SAVED',
  PREROLL = 'PREROLL',
  AD_BLOCKER = 'AD_BLOCKER',
  REWARD = 'REWARD',
  INTERSTITIAL = 'INTERSTITIAL',
}

export enum GameUnitState {
  FULLSCREEN_MOB = 'FULLSCREEN_MOB',
  FULLSCREEN_MOB_THEY_SELL = 'FULLSCREEN_MOB_THEY_SELL',
  FULLSCREEN_TAB = 'FULLSCREEN_TAB',
}

export const DefaultArena = 'https://arena51.arkadiumarena.com';
export const DefaultArenaDev = 'https://arena51-dev.arkadiumarena.com';
export const DEFAULT_AVATAR = 'avatar-11.png';

export enum IframeMessageTypes {
  PASS_GAME_DATA = 'PASS_GAME_DATA',
  UPDATE_LOCAL_STORAGE = 'UPDATE_LOCAL_STORAGE',
  REWARD_CALLBACK = 'REWARD_CALLBACK',
  INTERSTITIAL_CALLBACK = 'INTERSTITIAL_CALLBACK',
  CHECK_TO_STOP_MOBILE_CRAWLING = 'CHECK_TO_STOP_MOBILE_CRAWLING',
  ANALYTICS_GAME_DOWNLOAD = 'ANALYTICS_GAME_DOWNLOAD',
  RESIZE_CONTAINER = 'RESIZE_CONTAINER',
  SET_LS_ITEM = 'SET_LS_ITEM',
  REMOVE_LS_ITEM = 'REMOVE_LS_ITEM',
  AD_REFRESH = 'AD_REFRESH',
  UPDATE_COOKIE = 'UPDATE_COOKIE',
  UPDATE_INDEXED_DB = 'UPDATE_INDEXED_DB',
  UPDATE_LOCAL_STORAGE_ITEM = 'UPDATE_LOCAL_STORAGE_ITEM',
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  SET_AUTHORIZED_STATUS = 'SET_AUTHORIZED_STATUS',
  OPEN_LOGIN_POPUP = 'OPEN_LOGIN_POPUP',
  CLOSE_LOGIN_POPUP = 'CLOSE_LOGIN_POPUP',
  ARENA_TO_GAME_ACTION = 'ARENA_TO_GAME_ACTION',
}

export const customArenas = {
  wapo: ['games.washingtonpost.com', 'wapo-staging.arkadiumarena.com'],
  acs: ['acs-staging.arkadiumarena.com'],
  usat: ['games.usatoday.com', 'usatoday-staging.arkadiumarena.com', 'qa.games.usatoday.com'],
  currentlyATT: ['currently.arkadium.com'],
  digitalTurbine: ['digitalturbine.arkadiumarena.com', 'digitalturbine-staging.arkadiumarena.com'],
  triviaToday: ['games.triviatoday.com', 'triviatoday-staging.arkadiumarena.com'],
  theStar: ['games.thestar.com'],
  msn: ['puzzles.msn.com', 'msn-staging.arkadiumarena.com'],
};

export const OverflowHiddenBodyClassname = 'overflowHidden';
export const HeightOneHundredBodyClassname = 'heightOneHundred';

export enum LS_COOKIE_CONSTS {
  ADO_BUILD_ID = 'ado_build_id',
  IS_USER_ACCEPTED_PRIVACY_POLICY = 'is_user_accepted_privacy_policy',
  ARK_USER_AGREE_COOKIES_PRIVACY_POLICY = 'ark-user-agree-cookies-privacy-policy',
  FTUX_FIRST_VISIT = 'FTUXfirstvisit',
  GAME_APP_BANNER = 'GameAppBanner',
  ADBLOCK_TRACKER = 'adblock_tracker',
  TARGETING_CONSENT = 'targetingConsent',
  HIGH_SCORE = 'high_score',
  PE_SUB_DATA = 'PeSubData',
  RECENTLY_PLAYED = 'recently_played',
  TOKEN_EXPIRATION_TIME = 'ark_uup_token_expiration_time',
  GAME_PLAY_NUM = 'gamePlayNum',
  GAME_PLAY_VISIT = '_ark_gamePlayVisit',
  LAST_TIME_GAME_PLAYED = '_ark_gamePlayVisitTime',
  ARK_PC_OVERALL_GAMES_PLAYED = '_ark_pc_overallGamesPlayed',
  ARK_PC_LAST_GAME_PLAYED = '_ark_pc_lastGamePlayed',
  TIC_TAC_TOE_AB_TEST_SETTINGS = 'tic-tac-toe-ab-test-settings',
  USER_CHANGED_NAME = 'userChangedName',
  ARK_VISITOR_MAIN = 'ark_visitor_main',
  ARK_USER_FIRST_VISIT_2 = 'arkUserFirstVisit2',
  GA = '_ga',
  AD_FREE_VER = 'ark_adfree',
  AD_FREE_AVATAR = 'adFreeAv',
  AD_FREE_SPECIAL = 'adFreeSp',
  SUBSCRIPTION = 'ark_subscription',
  GAME_END_RESTART = 'ark_gameends',
  ARK_AFFILIATE = 'ark_affiliate',
  GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE = 'game_slug_for_subscription_purchase',
  SUMMER_PROMOTION_STATUS = 'summer_promotion_status',
  ARK_AFFILIATE_STORE_DAYS = 180, // how many days we store affiliate cookie
  SUBSCRIPTION_ID = 'ark_subscription_val',
  COLLECTION_LIST = 'ark_collection_list',
  COLLECTION_LIST_LOADING_DATE = 'ark_collection_list_loading_date',
  APPINSIGHT_USER_ID = 'ai_user',
}

export enum QUERY_STRING_CONSTS {
  GCLID = 'gclid',
  FB_CLID = 'fbclid',
  FB_ID = 'fbid',
  ARK_PROMO = 'arkpromo',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  FILENAME = 'filename',
  TYPE = 'type',
  ARKADS = '__arkads',
  ARKVER = '__arkver',
  ARKLOGIN = 'arklogin',
  ADD_FREE = 'ark_adfree',
  SUBSCRIPTION = 'ark_subscription',
  REDEMPTION_CODE = 'redemption_code',
  SUBSCRIPTION_PLAN = 'subscription_plan',
  FULL_SCREEN_TEST = 'fullscreen',
  ARK_AFFILIATE = 'affiliate',
  ARK_AFFILIATE_ID = 'affiliate_id',
  BLOG_ADS_TEST = 'blogAds',
  PACKAGE = 'package',
}

export type NestEnvironmentType =
  'dev'
  | 'qa'
  | 'stage'
  | 'prod';
