import * as Sentry from "@sentry/react";
import { environment } from './client/config/environment';

const isProduction: boolean = ['live', 'canary', 'staging'].includes(environment.ENV)

Sentry.init({
  dsn: 'https://b388a47f05d05a061cd20e1e8dfb5f54@sentry.arkadiumhosted.com/26',
  environment: BUILD_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["arena51-app-key"],
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],
  sampleRate: isProduction ? 0.01 : 1,
  // Performance Monitoring
  tracesSampleRate: isProduction ? 0.01 : 1,
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.01 : 1, 
  replaysOnErrorSampleRate: isProduction ? 0.01 : 1,
});
