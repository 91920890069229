import classNames from 'classnames';
import React from 'react';
import StickyBox from 'react-sticky-box';
import { AdDivider } from '../../molecules/AdDivider/AdDivider';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import TodaysSpecialAdWidget from '../../molecules/TodaysSpecial/TodaysSpecialAdWidget';
import YoutubeAdWidget from '../../molecules/YouTubeRender/YoutubeAdWidget';
import styles from './RightRailTheySell.css';

const DisplayAdRight = (props: any) => <div className={styles.displayAdRight} {...props} />;

interface RightRailProps {
    isLoginEnabled: boolean;
    isSticky: boolean;
    r1Sizes: number[][];
    r2Sizes: number[][];
    isHsn?: boolean;
}

interface MaybeStickyProps {
    enabled: boolean;
    offsetTop: number;
    offsetBottom: number;
}

const MaybeSticky: React.FC<MaybeStickyProps> = ({
                                                     enabled,
                                                     children,
                                                     ...props
                                                 }) =>
    enabled ? <StickyBox {...props}>{children}</StickyBox> : <React.Fragment>{children}</React.Fragment>;

export const RightRailTheySell = React.forwardRef<any, any>(
    (
        {
            isLoginEnabled,
            isSticky,
            r1Sizes,
            r2Sizes,
            isHsn,
        }: RightRailProps,
        ref,
    ) => (
        <div className={classNames('d-none d-lg-block col-auto', styles.rightRail)} ref={ref}>
            {isHsn && (
                <div className={styles.hsnWidgetsContainer}>
                    <YoutubeAdWidget/>
                    <TodaysSpecialAdWidget/>
                </div>
            )}
            <MaybeSticky enabled={isSticky} offsetBottom={20} offsetTop={20}>
                <DisplayAdRight data-element-description="game sidebar ad">
                    <DisplayAd
                        componentId="ark_display_r1"
                        dataElementDescription="ark-display-r1"
                        dimensions={r1Sizes}
                    />
                </DisplayAdRight>
                {isLoginEnabled && <AdDivider/>}
                <DisplayAdRight data-element-description="game sidebar ad">
                    <DisplayAd
                        componentId="ark_display_r2"
                        dataElementDescription="ark-display-r2"
                        dimensions={r2Sizes}
                    />
                </DisplayAdRight>
            </MaybeSticky>
        </div>
    ),
);
