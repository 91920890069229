import { hostnameToArenaDomain, trimUndefinedProps } from '../../../utils';
import { environment } from '../../config/environment';
import { AnalyticsGamePlayParams } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { KeysEnum } from '../../models/Enums';
import { ABTestService } from '../ABTestService';
import { authService } from '../AuthService';
import { DeviceDetector, DeviceType } from '../DeviceDetector';
import { LocalStorageService } from '../LocalStorage';
import { PwaService } from '../PwaService';
import { UrlService } from '../UrlService';
import { AppInsightsAnalytics } from './AppInsights';
import { initComeScore } from './Comscore';
import { GoogleAnalytics } from './Google';
import { GDPRMediatorService } from './GDPR';
import { QuantcastService } from '../QuantcastService';
import { CookieStorageService } from '../CookieStorage';
import { EagleLoginService } from '../EagleLoginService';
import { manageQADimension } from './QAGlobalDimenssions';

export const ARENA_VERSION = 'Arena 5.1';

class AnalyticsInitClass {
    public get domain() {
        return hostnameToArenaDomain(UrlService.domain);
    }

    public get deviceType() {
        const device = DeviceDetector.detectDevice();

        return device === DeviceType.MOBILE ? 'phone' : device;
    }
    public gameSlug: string;
    private fromPlayAgainOrRecGames: object;
    private userType: 'returning' | 'new';

    public isNewUser(): boolean {
        return this.userType === 'new';
    }

    public setGameSlug(key: string) {
        this.gameSlug = key;
        this.setGlobalCustomDimensions(undefined, { game: key });
    }

    public init(config: ArenaConfig, is404?: boolean) {
        const analyticsSettings = config.analytics;

        this.handleUserType();

        this.initGlobalCustomDimensions(is404);
        const blockedByGdprConsent = QuantcastService.blockedByGdprConsent();

        // Block for EU users
        GDPRMediatorService.init(config).then(() => {
            if (GDPRMediatorService.isAnalyticsEnabled) {
                GoogleAnalytics.init(analyticsSettings);

                if (analyticsSettings.additionalAccounts && analyticsSettings.additionalAccounts.length) {
                    analyticsSettings.additionalAccounts.forEach((settings) => {
                        const provider = settings.provider.toLowerCase();

                        switch (provider) {
                            case 'ga': {
                                GoogleAnalytics.addAdditionalAccount(settings);
                                break;
                            }

                            case 'comscore': {
                                initComeScore(settings.account);
                                break;
                            }
                        }
                    });
                }
            }
        });

        console.log('GDPRMediatorService', GDPRMediatorService);
        console.log('GDPRMediatorService.isAnalyticsEnabled', GDPRMediatorService.isAnalyticsEnabled);
        console.log('GDPRMediatorService.isGDPRUser', GDPRMediatorService.isGDPRUser);

        // Block by consent or config
        if (!analyticsSettings.disableAppInsights && !blockedByGdprConsent) {
            AppInsightsAnalytics.init();
        }
    }

    public setGlobalCustomDimensions(dimensionsGoogle = {}, dimensionsAppinsights = {}) {
        GoogleAnalytics.setCustomDimension(dimensionsGoogle);
        AppInsightsAnalytics.setCustomDimensions(dimensionsAppinsights);
    }

    public trackInconsistentArena(arenaOrigin: string) {
        if (process.env.NODE_ENV !== 'production') {
            return;
        }

        const hostname = window.location.hostname;
        const actualHostname = new URL(arenaOrigin).hostname;

        if (hostname !== actualHostname) {
            AppInsightsAnalytics.trackEvent('INCONSISTENT_ARENA', { hostname, actualHostname });
        }
    }

    public trackPageView(historyNavigation?: boolean, is404?: boolean, isError?: boolean) {
        const utmInfo = UrlService.getUtmInfo();
        const xsollaId = EagleLoginService.getUserFromStore()?.uid;
        const data = {
            ...utmInfo,
            arenaVersion: ARENA_VERSION,
            buildVersion: environment.BUILD_ID,
            pageType: UrlService.getPageType(is404, isError),
            deviceType: this.deviceType,
            urlReferrer: historyNavigation ? undefined : UrlService.getReferrerIfOuter(),
            xsollaId: xsollaId ?? '',
        };

        trimUndefinedProps(data);
        AppInsightsAnalytics.trackPageView(data);
    }

    public checkPlayAgainOrFromRecGames() {
        if (this.fromPlayAgainOrRecGames) {
            return this.fromPlayAgainOrRecGames;
        }

        const playAgainStart = LocalStorageService.getItem(AnalyticsGamePlayParams.playAgainStart);
        const recommendedGameStart = LocalStorageService.getItem(AnalyticsGamePlayParams.recommendedGameStart);
        const recPreviousGame = LocalStorageService.getItem(AnalyticsGamePlayParams.recPreviousGame);

        this.fromPlayAgainOrRecGames = {
            playAgainStart: playAgainStart ? playAgainStart : 'no',
            recommendedGameStart: recommendedGameStart ? recommendedGameStart : 'no',
        };

        if (recPreviousGame) {
            this.fromPlayAgainOrRecGames[AnalyticsGamePlayParams.recPreviousGame] = recPreviousGame;
        }

        LocalStorageService.removeItem(AnalyticsGamePlayParams.playAgainStart);
        LocalStorageService.removeItem(AnalyticsGamePlayParams.recommendedGameStart);
        LocalStorageService.removeItem(AnalyticsGamePlayParams.recPreviousGame);

        return this.fromPlayAgainOrRecGames;
    }

    public setPlayAgainOrFromRecGames(recPreviousGame?: string, playAgain?: string) {
        if (recPreviousGame) {
            LocalStorageService.setItem(AnalyticsGamePlayParams.recPreviousGame, recPreviousGame);
            LocalStorageService.setItem(AnalyticsGamePlayParams.recommendedGameStart, 'yes');
        }

        if (playAgain) {
            LocalStorageService.setItem(AnalyticsGamePlayParams.playAgainStart, playAgain);
        }
    }

    private initGlobalCustomDimensions( is404?: boolean) {
        const gaGlobalDimensions: any = {
            ABTestSlot1: ABTestService.getJewelShuffleABTestLocalStorageValue(),
            dimension14: window.navigator.userAgent,
        };
        const appInsightsPromoDimension: any = {
            ABTestSlot1: ABTestService.getJewelShuffleABTestLocalStorageValue(),
            arenaVersion: ARENA_VERSION,
            buildVersion: environment.BUILD_ID,
            userType: this.userType,
            domain: this.domain,
            deviceType: this.deviceType,
            pageType: UrlService.getPageType(is404),
            targetingConsent: LocalStorageService.getItem('targetingConsent') === 'true',
            xsollaId: EagleLoginService.getUserFromStore()?.uid ?? '',
            environment: environment.ENV
        };
        let arkpromo = UrlService.getArkPromo();

        if (PwaService.isPwaStandalone()) {
            arkpromo = 'PWA';
        }

        if (arkpromo === 'mgid' || arkpromo === 'adwords') {
            gaGlobalDimensions.dimension17 = arkpromo;
            appInsightsPromoDimension.paidPromo = arkpromo;
        } else if (arkpromo) {
            gaGlobalDimensions.dimension11 = arkpromo;
            appInsightsPromoDimension.offsitePromo = arkpromo;
        }

        // we assume that user is authorized if he has a token
        const token = authService.getToken();

        gaGlobalDimensions.dimension15 = token ? 1 : 0;
        appInsightsPromoDimension.loggedIn = token ? 1 : 0;

        const isQaTestData = Boolean(manageQADimension());

        if (isQaTestData) {
            appInsightsPromoDimension.qaTestData = isQaTestData;
            gaGlobalDimensions.dimension12 = isQaTestData;
        }

        this.setGlobalCustomDimensions(gaGlobalDimensions, appInsightsPromoDimension);
    }

    private handleUserType() {
        let arena4FirstVisit = CookieStorageService.get(KeysEnum.arena4FirstVisitAt);

        if (arena4FirstVisit) {
            try {
                arena4FirstVisit = decodeURIComponent(arena4FirstVisit);
                CookieStorageService.set(KeysEnum.arkFirstVisitAt, new Date(arena4FirstVisit).toISOString());
                CookieStorageService.remove(KeysEnum.arena4FirstVisitAt);
            } catch (e) {
                // Swallow
            }
        }

        const firstVisit = CookieStorageService.get(KeysEnum.arkFirstVisitAt) || new Date().toISOString();
        const cookieLifeTime = { expires: 365, path: '/' };

        CookieStorageService.set(KeysEnum.arkFirstVisitAt, firstVisit, cookieLifeTime);

        const sessionDurationHours = (Date.now() - Date.parse(firstVisit)) / 1000 / 60 / 60;

        this.userType = sessionDurationHours >= 1 ? 'returning' : 'new';

        CookieStorageService.set(KeysEnum.arkUserType, this.userType, cookieLifeTime);
    }
}

export const AnalyticsInit = new AnalyticsInitClass();
