import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isServer } from '../../../utils';
import { AdSettings } from '../../models/AdSettings';
import { ABTestManager } from '../../services/ABTests/ABTestManager';
import { ABTestContext } from '../../services/ABTests/ABTestReact';
import { AppState } from '../../store/types';
import styles from './DisplayAd.css';

interface DisplayAdProps {
    dimensions: number[][];
    adSettings: AdSettings;
    className?: string;
    componentId: string;
    dataElementDescription: string;
    showTitle?: boolean;
}

const debugDisplayAd = !isServer && window.location.hostname === 'localhost';

class DisplayAdBase extends React.PureComponent<DisplayAdProps & WithTranslation> {
    static contextType = ABTestContext;

    get adOptions() {
        const { adSettings, dimensions, componentId } = this.props;
        const widthList = dimensions.map((dimension) => dimension[0]);
        const heightList = dimensions.map((dimension) => dimension[1]);

        return {
            'data-id': componentId,
            product: adSettings.product,
            adUnit: adSettings.adUnit,
            dimensions: JSON.stringify(dimensions),
            style: {
                minWidth: Math.min.apply(null, widthList) || 'auto',
                minHeight: Math.min.apply(null, heightList) || 'auto',
                background: debugDisplayAd ? '#646B79' : null,
            },
        };
    }

    render() {
        const { t, className, dataElementDescription, showTitle = true } = this.props;
        const options: any = this.adOptions;
        const abMananger: ABTestManager = this.context;

        // Add keyValues for ABTestManager
        if (abMananger.hasTests()) {
            options.keyValues = JSON.stringify(abMananger.getKeyValues());
        }

        return (
            <Container className={className} data-element-description={dataElementDescription}>
                {showTitle && (<Caption>{t('AD_CAPTURE')}</Caption>)}
                <display-ad-component {...options} />
            </Container>
        );
    }
}

const Caption = (props) => (
    <p className={styles.caption} {...props}>
        {props.children}
    </p>
);
const Container = ({ className, ...props }) => <div className={classNames(styles.container, className)} {...props} />;
const DisplayAdTrans = withTranslation()(DisplayAdBase);

export const DisplayAd = connect((state: AppState) => ({
    adSettings: state.config.ad,
}))(DisplayAdTrans);
