import { LocalStorageService } from './LocalStorage';

class ABTestServiceFactory {

    public getJewelShuffleABTestLocalStorageValue(): string {
        const val = LocalStorageService.getItem('jewel-shuffle-ab-test-settings');

        if (val) {
            return JSON.parse(val).group;
        }

        return '';
    }
}

export const ABTestService = new ABTestServiceFactory();
