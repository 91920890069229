import { createAction, createReducer } from 'redux-act';
import { TItem } from '../../models/CategoryPageText';

export const setCategoryText = createAction<TItem[]>('Set category page text');

const initialState: TItem[] = [];
const reducer = createReducer<TItem[]>({}, initialState);

reducer.on(setCategoryText, (_state, payload: TItem[] = []) => payload);
export default reducer;
